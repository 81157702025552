import React, { useState, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'

import { Card, Grid, Divider } from '@mui/material'
import ProgressBar from 'components/progressBar/index'

import AuthLayout from 'layouts/auth'
import Seo from 'components/seo'
import SimpleStepper from 'components/stepper/simple'
import Icon from 'components/icon'
import StripePayment from 'components/stripe/index'

import { paymentQuestionList, contactUsQuestion, addPaymentAdvantageList } from '/static/data/addPayment.static'

import { sendDataLayerEvent } from 'utils'

const AddPaymentPage = ({ setMessageDialogOpen, setErrorMessageI18n }) => {
  const jwtToken = Cookies.get('jwtToken')
  const { t, i18n } = useTranslation()
  const stripeRef = useRef(null)
  const [isProcessLoading, setIsProcessLoading] = useState(true)
  const [isActionLoading, setIsActionLoading] = useState(true)
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('')

  const steps = ['onBoardingSteps.0', 'onBoardingSteps.1', 'onBoardingSteps.2']

  const CustomTransComponents = (text) => ({
    b: <span className="font-bold">{text}</span>
  })

  const getPlanId = async () => {
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL

      const response = await fetch(`${apiUrl}/billing/v2/plan/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })

      const json = await response.json()

      const planList = json.data
      const subscribePlan = planList.find((plan) => plan.price === 0)

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }
      return subscribePlan.id
    } catch (error) {
      const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
        ? `errorMessages.${error?.response?.code}`
        : 'errorMessages.getPlan'
      setErrorMessageI18n(errorMessage)
      setMessageDialogOpen(true)
    }
  }

  const createSubscription = async (paymentIntentToken, planId) => {
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL

      const payload = {
        plan_id: planId,
        id: paymentIntentToken
      }

      const response = await fetch(`${apiUrl}/billing/v2/payment_source/subscribe/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      const json = await response.json()

      const eventPayload = {
        event: 'add_payment_info',
        ecommerce: {
          currency: 'USD',
          payment_type: 'card'
        }
      }

      sendDataLayerEvent(eventPayload)

      navigate('/select-service')

      Cookies.remove('stripeClientSecret')

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }
    } catch (error) {
      const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
        ? `errorMessages.${error?.response?.code}`
        : 'errorMessages.addPayment'
      setErrorMessageI18n(errorMessage)
      setMessageDialogOpen(true)
      const eventPayload = {
        event: 'add_payment_error',
        reason: errorMessage
      }

      sendDataLayerEvent(eventPayload)
    }
  }

  const handleSubmit = async () => {
    setIsProcessLoading(true)
    setIsActionLoading(true)
    const { status, errorMessage } = await stripeRef.current.confirm()
    if (errorMessage) setPaymentErrorMessage(errorMessage)
    if (!status) {
      setIsProcessLoading(false)
      setIsActionLoading(false)
    }
  }

  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const paymentIntentToken = urlParams.get('payment_intent')

      const handleSubscribe = async (paymentIntentToken) => {
        const planId = await getPlanId()
        if (planId) {
          createSubscription(paymentIntentToken, planId)
        } else {
          window.location.reload()
        }
      }

      if (urlParams) {
        const redirectStatus = urlParams.get('redirect_status')
        if (redirectStatus && redirectStatus === 'succeeded' && paymentIntentToken) {
          handleSubscribe(paymentIntentToken)
        } else {
          setIsProcessLoading(false)
          setIsActionLoading(false)
        }
      }
    }
  }, [])

  return (
    <AuthLayout>
      {jwtToken ? (
        <React.Fragment>
          <Seo
            seo={{
              metaTitle: 'Add payment | Mlytics',
              metaDescription: 'Set up your payment',
              metaUrl: 'https://www.mlytics.com/add-payment/',
              shareImage: {
                imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
                imageWidth: 1200,
                imageHeight: 630,
                imageType: 'image/jpeg'
              }
            }}
          />
          <div className={`${isProcessLoading ? 'hidden' : ''}`}>
            <section className="relative container">
              <SimpleStepper
                steps={steps}
                className="py-10 min-w-fit max-w-[600px] mx-auto"
                currentStepIndex={0}
                orientation="horizontal"
              />
            </section>

            <section className="relative container py-5">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 w-full min-h-[250px] justify-between">
                <div className="col-span-1">
                  {paymentErrorMessage ? (
                    <div className="mb-3 p-5 bg-error-500 bg-opacity-20 rounded">
                      <h4 className="text-error-500 font-semibold">{t('errorMessageTitle')}</h4>
                      <p className="text-black text-opacity-60">{paymentErrorMessage}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="mb-3 p-5 bg-blue-300 bg-opacity-5 rounded">
                    {paymentQuestionList.map((paymentQuestion, paymentQuestionIndex) => (
                      <div
                        className={`${paymentQuestionIndex < paymentQuestionList.length - 1 ? 'mb-5' : ''}`}
                        key={paymentQuestionIndex}
                      >
                        <h4 className="text-gray-800 font-semibold">{t(paymentQuestion.question)}</h4>
                        <p className="text-gray-700 text-opacity-60">{t(paymentQuestion.answer)}</p>
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-blue-300 bg-opacity-5 rounded">
                    <h4 className="text-gray-800 font-semibold">{t(contactUsQuestion.question)}</h4>
                    <p className="text-gray-700 text-opacity-60">
                      <Trans i18nKey={contactUsQuestion.answer}>
                        <a
                          className="text-secondary-600 underline underline-offset-2"
                          href="/lp/get-a-demo/"
                          target="_blank"
                        ></a>
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <Card
                    className="p-5 lg:p-10"
                    sx={{
                      boxShadow: '0px 8px 36px 0px rgba(0,0,0, 0.1)',
                      borderRadius: '12px'
                    }}
                  >
                    <StripePayment className="mb-6" ref={stripeRef} />
                    <p className="mb-5 text-gray-800">{t('addPaymentPage.creditCardStatement.0')}</p>
                    <p className="text-gray-800">
                      <Trans i18nKey="addPaymentPage.creditCardStatement.1" components={CustomTransComponents()} />
                    </p>
                    <Divider
                      sx={{
                        marginBottom: '24px',
                        padding: '12px 0px'
                      }}
                    />
                    <div className="flex flex-col lg:flex-row gap-5">
                      <div className="p-2 bg-blue-200 bg-opacity-10 flex-1 rounded">
                        {addPaymentAdvantageList.map((advantageItem, advantageItemIndex) => (
                          <div key={advantageItemIndex} className="flex items-start text-blue-200 text-sm">
                            <Icon className="mr-1" name="check" fontSize="18px" />
                            <span>{t(advantageItem)}</span>
                          </div>
                        ))}
                      </div>
                      <button
                        className={`btn btn-blue btn-xs ${isActionLoading ? 'disabled' : ''}`}
                        style={{
                          minWidth: '280px',
                          maxWidth: '100%'
                        }}
                        disabled={isActionLoading}
                        onClick={handleSubmit}
                      >
                        {isActionLoading ? (
                          <svg
                            className="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-r-gray-50 border-t-gray-50"
                            style={{
                              width: '24px',
                              height: '24px'
                            }}
                            viewBox="0 0 32 32"
                          ></svg>
                        ) : (
                          <span>{t('button.addPayment')}</span>
                        )}
                      </button>
                    </div>
                  </Card>
                </div>
              </div>
            </section>
          </div>
          <div
            className={`py-10 px-5 flex items-center justify-center ${isProcessLoading ? '' : 'hidden'}`}
            style={{
              height: 'calc(100vh - 160px)'
            }}
          >
            <Card
              className="relative rounded-xl p-8 pt-16"
              sx={{
                margin: '0 auto',
                boxShadow: '0px 8px 36px 0px rgba(0,0,0, 0.1)',
                overflow: 'visible',
                minHeight: '250px',
                width: '100%',
                maxWidth: '535px'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '50%',
                  transform: 'translate(-50%, -43px)'
                }}
              >
                <div
                  className="p-2 bg-white rounded-full flex items-center justify-center"
                  style={{ width: '86px', height: '86px', boxShadow: '0px 8px 36px 0px rgba(0,0,0, 0.1)' }}
                >
                  <Icon className="text-blue-200" name="account_balance_outlined" fontSize="43px" />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <Grid xs item className="text-center">
                  <ProgressBar className="mb-6" />
                  <p className="mb-6 text-gray-800">{t('addPaymentPage.processLoadingCard.notice.0')}</p>
                  <p className="text-gray-800">
                    <Trans i18nKey="addPaymentPage.processLoadingCard.notice.1" components={CustomTransComponents()} />
                  </p>
                </Grid>
              </div>
            </Card>
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </AuthLayout>
  )
}
AddPaymentPage.propTypes = {
  setMessageDialogOpen: PropTypes.func,
  setErrorMessageI18n: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  setMessageDialogOpen: (isOpen) => dispatch({ type: 'SET_MESSAGE_DIALOG_OPEN', payload: isOpen }),
  setErrorMessageI18n: (i18nKey) => dispatch({ type: 'SET_ERROR_MESSAGE_I18N_KEY', payload: i18nKey })
})

export default connect(null, mapDispatchToProps)(AddPaymentPage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
