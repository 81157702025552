const paymentQuestionList = [
  {
    question: 'addPaymentPage.paymentQuestionList.0.question',
    answer: 'addPaymentPage.paymentQuestionList.0.answer'
  },
  {
    question: 'addPaymentPage.paymentQuestionList.1.question',
    answer: 'addPaymentPage.paymentQuestionList.1.answer'
  },
  {
    question: 'addPaymentPage.paymentQuestionList.2.question',
    answer: 'addPaymentPage.paymentQuestionList.2.answer'
  }
]

const contactUsQuestion = {
  question: 'addPaymentPage.contactUsQuestion.question',
  answer: 'addPaymentPage.contactUsQuestion.answer'
}

const addPaymentAdvantageList = ['Pay-as-you-go pricing', 'Add payment to get your free $50 USD credit']
export { paymentQuestionList, contactUsQuestion, addPaymentAdvantageList }
