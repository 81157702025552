import React, { useRef, useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, Elements, PaymentElement, AddressElement } from '@stripe/react-stripe-js'
import Cookies from 'js-cookie'
import { sendDataLayerEvent } from 'utils'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

const StripeForm = forwardRef(({ planId }, ref) => {
  const { i18n } = useTranslation()
  const jwtToken = Cookies.get('jwtToken')
  if (jwtToken) {
    const stripe = useStripe()
    const elements = useElements()

    const handleReturnUrl = () => {
      let url = ''
      let queryString = ''
      const { origin, pathname } = window.location
      if (planId) {
        queryString = `?planId=${planId}`
      }

      url = origin + pathname + queryString
      return url
    }

    const returnUrl = handleReturnUrl()

    const handleConfirmPayment = async () => {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }
      try {
        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: returnUrl
          }
        })
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          const { code, message } = result.error
          const errorMessage = i18n.exists(`errorMessages.${code}`) ? `errorMessages.${code}` : message
          sendDataLayerEvent({
            event: 'credit_card_error',
            reason: errorMessage
          })
          return {
            status: false,
            errorMessage
          }
        }
        return {
          status: true
        }
      } catch (error) {
        console.log(error)
        return {
          status: false
        }
      }
    }

    const clearElementsValue = () => {
      if (elements) {
        elements._elements.forEach((element) => element.clear())
      }
    }

    useEffect(() => {
      clearElementsValue()
    }, [elements])

    useImperativeHandle(ref, () => ({
      clear() {
        clearElementsValue()
      },
      confirm() {
        return handleConfirmPayment()
      }
    }))
  }
  return (
    <div ref={ref}>
      <PaymentElement options={{ terms: { card: 'never' } }} />
      <AddressElement options={{ mode: 'billing' }} />
    </div>
  )
})

const StripePaymentComponent = forwardRef(({ className, planId }, ref) => {
  const jwtToken = Cookies.get('jwtToken')

  const [clientSecret, setClientSecret] = useState('')
  const formRef = useRef(null)

  const generateClientSecret = async () => {
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL

      const response = await fetch(`${apiUrl}/billing/v2/payment_source/intent_token/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })

      const json = await response.json()
      const clientSecret = json?.data?.client_secret

      setClientSecret(clientSecret)
      Cookies.set('stripeClientSecret', clientSecret)

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }
    } catch (error) {
      console.log(error)
    }
  }

  useLayoutEffect(() => {
    const clientSecretCookies = Cookies.get('stripeClientSecret')
    if (!clientSecretCookies) {
      generateClientSecret()
    } else {
      setClientSecret(clientSecretCookies)
    }
  }, [])

  const options = {
    clientSecret: clientSecret
  }

  useImperativeHandle(ref, () => ({
    clear() {
      if (formRef && formRef?.current) {
        formRef.current.clear()
      }
    },
    confirm() {
      if (formRef && formRef?.current) {
        return formRef.current.confirm()
      }
    }
  }))

  return (
    <div className={className} ref={ref}>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <StripeForm ref={formRef} planId={planId} />
        </Elements>
      ) : null}
    </div>
  )
})

StripeForm.displayName = 'StripeForm'

StripeForm.propTypes = {
  planId: PropTypes.string
}

StripePaymentComponent.displayName = 'StripePayment'

StripePaymentComponent.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string
}

export default StripePaymentComponent
